import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container main" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "header" }
const _hoisted_5 = {
  key: 0,
  class: "text-success mx-3"
}
const _hoisted_6 = { class: "form d-inline-block" }
const _hoisted_7 = {
  key: 0,
  class: "error"
}
const _hoisted_8 = { class: "d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[2] || (_cache[2] = _createElementVNode("div", { class: "header-image" }, null, -1)),
          _createVNode(_component_Card, { heading: "Wachtwoord vergeten" }, {
            body: _withCtx(() => [
              ($setup.email.isSuccessful)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, " U ontvangt een e-mail met daarin een link om uw nieuwe wachtwoord in te stellen. "))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_TextInput, {
                  modelValue: $setup.email.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email.value) = $event)),
                  label: $setup.email.label,
                  type: $setup.email.type,
                  "has-errors": $setup.email.hasErrors,
                  error: $setup.email.error,
                  onBlur: $setup.validateEmail
                }, null, 8, ["modelValue", "label", "type", "has-errors", "error", "onBlur"]),
                ($setup.error)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString($setup.error), 1))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_Button, {
                    type: "button",
                    design: "primary",
                    text: "Verzend",
                    onButtonClick: $setup.login
                  }, null, 8, ["onButtonClick"]),
                  _createElementVNode("p", null, [
                    _createVNode(_component_router_link, {
                      class: "inline-router-link small",
                      to: { name: 'Login' }
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createElementVNode("span", null, "Terug naar login", -1)
                      ])),
                      _: 1
                    })
                  ])
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}