
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import router from '@/router';
import Button from '@/shared/components/Button.vue';
import Card from '@/shared/components/Card.vue';
import TextInput from '@/shared/components/form/TextInput.vue';

export default {
    components: {
        Card,
        TextInput,
        Button,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const error = ref('');
        const token = ref(route.params.token);
        const email = ref(route.params.email);

        const password = ref({
            value: '',
            label: 'Nieuw Wachtwoord',
            type: 'password',
            error: 'Minimaal 1 hoofdletter, 1 cijfer, 1 kleine letter en minimaal 6 tekens lang',
            hasErrors: false,
        });

        const passwordRepeat = ref({
            value: '',
            label: 'Herhaal Nieuw Wachtwoord',
            type: 'password',
            error: 'Wachtwoorden komen niet overeen',
            hasErrors: false,
        });

        function validatePassword() {
            const re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
            password.value.hasErrors = !re.test(password.value.value);
        }

        function validatePasswordRepeat() {
            const isSame = password.value.value === passwordRepeat.value.value;
            passwordRepeat.value.hasErrors = !isSame;
        }

        function reset() {
            const formData = {
                token: token.value,
                email: email.value,
                password: password.value.value,
                password_confirmation: passwordRepeat.value.value,
            };

            store.dispatch('RESET_PASSWORD', formData).then(() => {
                router.push({ name: 'Login' });
            }).catch((err) => {
                if (err.response.data.data.type === 'error') {
                    error.value = err.response.data.data.attributes.message;
                }
            });
        }

        return {
            reset,
            error,
            passwordRepeat,
            password,
            validatePassword,
            validatePasswordRepeat,
        };
    },
};
