
import { ref } from 'vue';
import { useStore } from 'vuex';
import router from '@/router';
import Button from '@/shared/components/Button.vue';
import Card from '@/shared/components/Card.vue';
import TextInput from '@/shared/components/form/TextInput.vue';

export default {
    components: {
        Card,
        TextInput,
        Button,
    },
    setup() {
        const store = useStore();
        const error = ref('');
        const email = ref({
            value: '',
            label: 'E-mailadres',
            type: 'email',
            error: 'E-mailadres is ongeldig.',
            hasErrors: false,
            isSuccessful: false,
        });

        function validateEmail() {
            const re = /\S+@\S+\.\S+/;
            email.value.hasErrors = !re.test(email.value.value);
        }

        function login() {
            const emailVal = email.value;

            const formData = {
                email: emailVal.value,
            };

            validateEmail();

            if (emailVal.hasErrors) {
                return;
            }

            store.dispatch('FORGOT_PASSWORD', formData).then(() => {
                email.value.isSuccessful = true;
            }).catch((err) => {
                if (err.response.data.data.type === 'error') {
                    error.value = err.response.data.data.attributes.message;
                }
            });
        }

        return {
            email,
            validateEmail,
            login,
            error,
            store,
        };
    },
};
