<template>
    <div class="kwast-card">
        <h1 class="kwast-card-title">
            {{ heading }}
        </h1>
        <p class="kwast-card-subtitle">
            <slot name="subtitle"/>
        </p>
        <slot name="body"/>
    </div>
</template>

<script type="ts">
export default {
    props: {
        heading: {
            default: 'Heading',
            type: String,
            required: false,
        },
    },
    setup() {
        return {
            //
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/assets/variables";
.kwast-card {
    width: 31.625rem;
    background: $white;
    margin: -6.25rem 0 8.75rem 0;
    padding-bottom: 3.125rem;

    &-title {
        margin: 3.5625rem 0 0 0;
        font-family: 'Martel';
        font-size: 50px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: $kwast-red;
    }

    &-subtitle {
        margin: 1.25rem 0 1.5625rem 0;
        font-family: 'DINPro';
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.63;
        letter-spacing: normal;
        text-align: center;
        color: $black;
    }
}
</style>
